
import React from 'react'
import styled from "styled-components"

const BoxStyling = styled.div`
    background: linear-gradient(140deg,#e6f4cb,#fff 50%);
    border-radius: 1rem;
    padding: 2rem;
    margin: 1.5rem 0;
`
let FeatureBlock = ({children, ...opts}) => (
  <BoxStyling {...opts}>
    {children}
  </BoxStyling>
)

export default FeatureBlock


    