import React from 'react'
import PageTemplate from '../components/pageTemplate'
import FeautureBlock from '../components/feautureBlock'

let About = () => (
  <PageTemplate title="Тарифы">
    <FeautureBlock>
      <h3>Тестовый</h3>
      <p>Добавляйте до 500 физических лиц и пользуйтесь полным функционалом сервиса в течении одного месяца</p>
    </FeautureBlock>
    <FeautureBlock>
      <h3>Для бизнеса</h3>
      <p>Неограниченное количество физических лиц и использование API для интеграции с Вашей информационной системой.</p>
    </FeautureBlock>
    <FeautureBlock>
      <h3>Как начать работу с сервисом?</h3>
      <p>Вы можете оставить заявку на нашем сайте или отправить Ваши контакты на info@debttracker.ru</p>
      <p>Стоимость рассчитывается индивидуально в зависимости от количества физических лиц.</p>
    </FeautureBlock>
  </PageTemplate>
)

export default About
