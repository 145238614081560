import React from 'react'
import styled from 'styled-components'

const Background = styled.div`
  background: rgb(45,129,173);
  background: linear-gradient(140deg, rgba(45,129,173,1) 0%, rgba(24,100,139,1) 100%);
  color: white;
  padding: 3rem 0;
  text-align: center;

  @media (max-width: ${props => props.theme.screen.sm}) {
    h1 {
      font-size:2rem !important;
    }
  }
`

let PageTitle = ({title}) => (
  <Background>
    <h1>{title}</h1>
  </Background>
)

export default PageTitle
